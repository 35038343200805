<template>
  <div class="animated fadeIn">
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <b-row>
      <b-col sm="4">
        <b-card>
          <b-form-group
            id="input-group-name"
            label="Огноо:"
            label-for="input-name"
            description="Хамгийн ихдээ 90 хоног сонгоно уу"
          >
            <date-range-picker
              class="w-100"
              v-model="dateRange"
              :locale-data="locale"
              :opens="opens"
              :autoApply="true"
              :ranges="ranges"
              :timePicker="false"
            >
            </date-range-picker>
          </b-form-group>
          <b-button @click="showData()">Харах</b-button>
        </b-card>
      </b-col>
      <b-col sm="8">
        <b-card>
          <div v-if="data.length > 0">
            <b-table :fields="fields" striped hover :items="data">
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template v-slot:cell(userFirstname)="data">
                @{{ data.item.userFirstname }}
              </template>

              <template v-slot:cell(successCount)="data">
                <strong>{{ data.item.successCount }}</strong>
              </template>
            </b-table>
          </div>
          <b-alert variant="warning" v-else show class="mb-0">
            Мэдээлэл байхгүй байна
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css";

export default {
  name: "Report.list.month",
  components: {
    DateRangePicker,
    axios,
  },
  data: function() {
    return {
      isLoading: false,
      today: moment().format("YYYY-MM-DD"),
      alwaysShowCalendars: false,
      linkedCalendars: false,
      ranges: {
        Өнөөдөр: [moment(), moment()],
        Өчигдөр: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Сүүлийн 30 хоног": [moment().subtract(29, "days"), moment()],
        "Сүүлийн 90 хоног": [moment().subtract(89, "days"), moment()],
        "Энэ сар": [moment().startOf("month"), moment().endOf("month")],
        "Өмнөх сар": [
          moment()
            .subtract(1, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month"),
        ],
      },
      dateRange: {
        startDate: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      opens: "right",
      locale: {
        separator: " - ",
        format: "YYYY-MM-DD",
        customRangeLabel: "Өдөр сонгох",
        daysOfWeek: ["Ня", "Да", "Мя", "Лх", "Пү", "Ба", "Бя"],
        monthNames: [
          "1-р сар",
          "2-р сар",
          "3-р сар",
          "4-р сар",
          "5-р сар",
          "6-р сар",
          "7-р сар",
          "8-р сар",
          "9-р сар",
          "10-р сар",
          "11-р сар",
          "12-р сар",
        ],
        firstDay: 1,
      },
      data: [],
      fields: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Ажилтан",
          key: "userFirstname",
          class: "text-left",
          sortable: true,
        },
        {
          label: "PDF татсан тоо",
          key: "successCount",
          class: "text-center",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    getStartDate() {
      return moment(this.dateRange.startDate).format("YYYY-MM-DD");
    },
    getEndDate() {
      return moment(this.dateRange.endDate).format("YYYY-MM-DD");
    },
  },
  methods: {
    showData: async function() {
      this.isLoading = true;
      try {
        const response = await axios.post(
          this.$config.NUMUR_MS_ADMIN_URL + "get_pdf_download_data", // Updated endpoint URL
          new URLSearchParams({
            startDate: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded", // This matches the format sent in Flask backend
            },
          }
        );

        this.isLoading = false;

        if (response.data.responseResultType === "RESPONSE_FAILURE") {
          this.showToast("Алдаа", "Алдаа гарсан байна. Дахин оролдоно уу", "danger");
        } else {
          this.data = response.data.list;
        }
      } catch (error) {
        this.isLoading = false;
        this.showToast("Алдаа", "Алдаа гарсан байна. Дахин оролдоно уу", "danger");
      }
    },
  },
};
</script>
